import {ButtonColor, ButtonSize} from './options';
import useButtonStyle from './useButtonStyle';
import useLabelStyles from './useLabelStyle';
import isNonEmptyString from '../../../isNonEmptyString';
import {CSSStyles} from '../../../types/CSSStyles';
import Flex from '../../layout/flex/Flex';
import useAppTheme from '../hooks/useAppTheme';
import Link from '../Link/Link';

import {SerializedStyles, css} from '@emotion/react';
import useMergedRef from '@react-hook/merged-ref';
import React, {forwardRef, useRef} from 'react';
import {AriaButtonOptions, useButton} from 'react-aria';

export type ButtonStyles = Readonly<{
  default: SerializedStyles;
  disabled: SerializedStyles;
  down: SerializedStyles;
  focus: SerializedStyles;
  hover: SerializedStyles;
}>;

type ButtonPropOmitKeys = 'children';

export interface Props
  extends Omit<AriaButtonOptions<'button'>, ButtonPropOmitKeys> {
  readonly label: React.ReactNode;
  /**
   * @default primary
   */
  readonly color?: ButtonColor;
  readonly icon?: React.ReactNode;
  readonly labelIsHidden?: boolean;
  /**
   * @default large
   */
  readonly size?: ButtonSize;
  readonly sx?: CSSStyles;
  readonly to?: string;
}

function Button(
  props: Props,
  parentRef: React.ForwardedRef<unknown>,
): JSX.Element {
  const {
    color,
    icon,
    label,
    labelIsHidden,
    size,
    sx: rootStyle,
    to,
    ...buttonProps
  } = props;
  const ref = useRef(null);
  const mergedRef = useMergedRef(parentRef, ref);
  const {spacing} = useAppTheme();
  const {buttonProps: buttonRootProps} = useButton(buttonProps, ref);
  const buttonStyle = useButtonStyle({
    color,
    disabled: Boolean(buttonRootProps.disabled),
    size,
  });

  const labelStyle = useLabelStyles({size});

  if (isNonEmptyString(to)) {
    return (
      <Link
        sx={[
          buttonStyle,
          css({
            textAlign: 'center',
            textDecoration: 'none',
          }),
          rootStyle,
        ]}
        to={to}
        {...buttonProps}
      >
        {icon}
        {!labelIsHidden && <span css={labelStyle}>{label}</span>}
      </Link>
    );
  }

  return (
    <button
      css={[buttonStyle, rootStyle]}
      ref={mergedRef}
      {...buttonRootProps}
    >
      <Flex
        sx={[
          labelStyle,
          css({
            alignItems: 'center',
            gap: spacing.x8,
            justifyContent: 'center',
          }),
        ]}
      >
        {icon}
        <div
          css={[
            labelIsHidden &&
              css({
                display: 'none',
              }),
          ]}
        >
          {label}
        </div>
      </Flex>
    </button>
  );
}

export default forwardRef(Button);
