export enum ButtonColor {
  ACCENT = 'accent',
  PRIMARY = 'primary',
  PRIMARY_CONTRAST = 'primary-contrast',
  SECONDARY = 'secondary',
  SECONDARY_SUBDUE = 'secondary-subdue',
  SECONDARY_CONTRAST = 'secondary-contrast',
  TRANSPARENT = 'transparent',
}

export enum ButtonSize {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}
